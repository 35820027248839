import { Box, Menu, useMantineTheme } from '@mantine/core';
import { useNodeContext } from '../../context';
import { Options } from '@morph-mapper/ui';
import { OptionType, OptionVisibility } from '@morph-mapper/types';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import { LuEye, LuEyeClosed } from 'react-icons/lu';

interface OptionMenuProps {
  name: string;
}

export const OptionMenu = ({ name }: OptionMenuProps) => {
  const theme = useMantineTheme();
  const [type, visibility, setOptionType, setOptionVisibility] = useNodeContext(
    (s) => [
      s.getOptionType(name),
      s.getOptionVisibility(name),
      s.setOptionType,
      s.setOptionVisibility,
    ]
  );

  const isExposed = visibility === OptionVisibility.Exposed;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Options />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Type</Menu.Label>
          <Menu.Item
            icon={
              type === OptionType.UserDefined ? (
                <IoMdRadioButtonOn color={theme.colors.blue[7]} />
              ) : (
                <IoMdRadioButtonOff color={theme.colors.gray[6]} />
              )
            }
            onClick={() => setOptionType(name, OptionType.UserDefined)}
          >
            Self defined
          </Menu.Item>
          <Menu.Item
            icon={
              type === OptionType.Dependency ? (
                <IoMdRadioButtonOn color={theme.colors.blue[7]} />
              ) : (
                <IoMdRadioButtonOff color={theme.colors.gray[6]} />
              )
            }
            onClick={() => setOptionType(name, OptionType.Dependency)}
          >
            Graph defined
          </Menu.Item>
          <Menu.Item
            icon={
              type === OptionType.Reference ? (
                <IoMdRadioButtonOn color={theme.colors.blue[7]} />
              ) : (
                <IoMdRadioButtonOff color={theme.colors.gray[6]} />
              )
            }
            onClick={() => setOptionType(name, OptionType.Reference)}
          >
            Variable reference
          </Menu.Item>
          <Menu.Label>Visibility</Menu.Label>
          <Menu.Item
            icon={
              isExposed ? (
                <LuEye color={theme.colors.gray[6]} />
              ) : (
                <LuEyeClosed color={theme.colors.gray[6]} />
              )
            }
            onClick={() =>
              setOptionVisibility(
                name,
                isExposed ? OptionVisibility.Internal : OptionVisibility.Exposed
              )
            }
          >
            {isExposed ? 'Hide' : 'Expose'}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};
