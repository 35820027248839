import { createStore } from 'zustand';
import _ from 'lodash';
import { immer } from 'zustand/middleware/immer';
import {
  EdgeChange,
  NodeChange,
  OnEdgesChange,
  OnNodesChange,
  applyEdgeChanges,
  applyNodeChanges,
} from 'reactflow';
import { Node, Edge, LogicGraph } from '@/types';
import { IGraph, StoreSlice } from '@/store';
import { getValues } from '@morph-mapper/utils';
import { DIRECTION } from '@morph-mapper/node-logic';

export interface GraphProps {
  id: string;
}

export type InitGraphProps = GraphProps;

export interface GraphState extends GraphProps {
  get: () => LogicGraph;
  // Local state operations
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;

  deleteNodes: (nodeIds: string[]) => void;

  isHandleConnected: (
    nodeId: string,
    handleId: string,
    direction: DIRECTION
  ) => boolean;
}

export type GraphStore = ReturnType<typeof createGraphStore>;

export const createGraphStore = (
  global: StoreSlice,
  initProps: InitGraphProps
) => {
  return createStore<GraphState & StoreSlice & IGraph>()(
    immer((set, get) => ({
      ...global,
      ...global.graphs.graph(initProps.id),
      ...initProps,

      onNodesChange: (changes: NodeChange[]) => {
        const { setNodes, getNodes } = get();

        setNodes(
          applyNodeChanges(changes, getValues(getNodes())).reduce(
            (acc, cur) => {
              acc[cur.id] = cur as Node;
              return acc;
            },
            {} as Record<string, Node>
          )
        );
      },
      onEdgesChange: (changes: EdgeChange[]) => {
        const { setEdges, getEdges } = get();

        setEdges(
          applyEdgeChanges(changes, getValues(getEdges())).reduce(
            (acc, cur) => {
              acc[cur.id] = cur as Edge;
              return acc;
            },
            {} as Record<string, Edge>
          )
        );
      },

      deleteNodes: (nodeIds) => {
        let newNodes = {};
        let newEdges = {};

        set((s) => {
          const nodes = s.getNodes();
          const edges = s.getEdges();

          const sourceEdges = _.filter(edges, (edge) =>
            nodeIds.includes(edge.source)
          );
          const targetEdges = _.filter(edges, (edge) =>
            nodeIds.includes(edge.target)
          );

          newNodes = _.omit(nodes, nodeIds);
          newEdges = _.omit(edges, [
            ...sourceEdges.map((edge) => edge.id),
            ...targetEdges.map((edge) => edge.id),
          ]);

          // FIXME: The delete update only registers directly in the graph
          // if we update the nodes through here (besides doing setNodes and setEdges).
          // This breaks the API but currently I could not find a fix, this should not be
          // necessary and requires further investigation.
          s.graphs.graphs[initProps.id].nodes = newNodes;
          s.graphs.graphs[initProps.id].edges = newEdges;
        });

        get().setNodes({ ...newNodes });
        get().setEdges({ ...newEdges });
      },

      isHandleConnected: (nodeId, handleId, direction) => {
        const edges = get().getEdges();
        if (!edges) throw new Error('Edges not found');
        let edge = undefined;

        switch (direction) {
          case 'source':
            edge = getValues(edges).find(
              (edge) => edge.source === nodeId && edge.sourceHandle === handleId
            );
            break;
          case 'target':
            edge = getValues(edges).find(
              (edge) => edge.target === nodeId && edge.targetHandle === handleId
            );
            break;
        }

        return edge ? true : false;
      },
    }))
  );
};
