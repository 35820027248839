import { getValues, hasProperty } from '@morph-mapper/utils';
import { EntryItemType, EntryType, SchemaVariant } from '@morph-mapper/types';
import { z } from 'zod';
import { Input } from '@morph-mapper/node-inputs';
import { StructureBlockIndex } from '@morph-mapper/node-logic';

export * from './config';

export enum SchemaType {
  Import = 'import',
  Export = 'export',
  Standard = 'standard',
  Invoice = 'invoice',
}

export type CustomSchemes = Partial<
  Record<
    string,
    Partial<Record<SchemaType, Partial<Record<SchemaVariant, string>>>>
  >
>;

export type PrefillContext = {
  name: string;
  operatorId: number;
};

export type SchemaEntry = {
  displayName?: string;
  validation: z.ZodType<any, any>;
  type: Exclude<EntryType, EntryType.Map>;
  allowedTypes?: EntryItemType[];
  prefill?: (ctx: PrefillContext) => any;
  configKey?: string;
};

export type SchemaMap = {
  displayName?: string;
  outputType?: StructureBlockIndex;
  configKey?: string;
  values: {
    [key: string]: SchemaEntry | SchemaMap;
  };
};

export type SchemaConfig = {
  [key: string]: {
    title: string;
    description?: string;
    hidden?: boolean;
    advanced?: boolean;
    childConfigurations?: string[];
    options: {
      [key: string]: {
        type: any;
        title: string;
        description: string;
        input: Input;
        selectionDeclareVariable?: boolean;
        selection?: {
          label: string;
          value: any;
        }[];
        default: any;
        placeholder?: string;
        options?: Partial<{
          required: boolean;
        }>;
        conditions?: Partial<Record<string, { hasValue?: any }>>;
      };
    };
    conditions?: Partial<Record<string, Record<string, { hasValue?: any }>>>;
    mutate: any;
  };
};

export type Schema = {
  updateTemplateDiscardFields: string[];
  config?: SchemaConfig;
  definition: SchemaMap;
};

export interface SupportedTemplates {
  [domain: string]: {
    name: string;
    description: string;
    schemas: {
      [type: string]: {
        [variant: string]: SchemaMap;
      };
    };
  };
}

export const isSchemaEntry = (entry: any): entry is SchemaEntry => {
  if (entry === undefined) return false;
  return getValues(EntryType).includes(entry.type);
};

export const isSchemaMap = (entry: any): entry is SchemaMap => {
  if (entry === undefined) return false;
  return hasProperty(entry, 'values');
};
