import { BlockRule, GraphNode, typeLogicBlockRecord } from '../../types';
import { LOGIC_BLOCKS as logic } from '../base';
import { Input } from '@morph-mapper/node-inputs';
import { z } from 'zod';
import { FiAnchor } from 'react-icons/fi';
import { HandleType } from '@morph-mapper/types';
import { TbColumns3 } from 'react-icons/tb';

const overrides = typeLogicBlockRecord({
  anchor: {
    title: 'Anchor',
    description:
      'Select text to act as a reference point of the value you want to extract.',
    category: 'core',
    type: 'anchor',
    node: GraphNode.Generic,
    icon: FiAnchor,
    mutation: ({ options }) => {
      const {
        reference,
        iterateRows,
        absColumn,
        absRow,
        relColumn,
        relRow,
        selected,
        whitespace,
      } = options;

      if (reference === 'relative' && !selected) return;

      let anchor = undefined;

      const renderRow = (row: number) => {
        if (iterateRows) return '>';
        return `${row},`;
      };

      if (reference === 'absolute') {
        anchor = `#${renderRow(absRow)}${absColumn}$get`;
      }
      if (reference === 'relative') {
        anchor = `#${renderRow(relRow)}${relColumn}#${selected}`;
      }

      if (whitespace) {
        return [[anchor]];
      } else {
        return anchor;
      }
    },
    options: {
      reference: {
        type: z.enum(['absolute', 'relative']),
        title: 'Reference',
        description: 'Make use of absolute or relative cell references.',
        input: Input.RadioSelector,
        selection: [
          { value: 'absolute', label: 'Absolute' },
          { value: 'relative', label: 'Relative' },
        ],
        default: 'absolute',
        conditions: {},
      },
      selected: {
        type: z.string(),
        title: 'Selector',
        description:
          'Select text from table which will act as a reference cell to extract data',
        input: Input.TextSelector,
        default: undefined,
        conditions: {
          hasValue: {
            reference: 'relative',
          },
        },
      },
      absColumn: {
        type: z.number(),
        title: 'Column',
        description: 'Select the column to extract data from',
        input: Input.Number,
        default: 0,
        conditions: {
          hasValue: {
            reference: 'absolute',
          },
        },
      },
      relColumn: {
        type: z.number(),
        title: 'Column',
        description: 'Shift columns from the reference cell',
        input: Input.Number,
        default: 0,
        conditions: {
          hasValue: {
            reference: 'relative',
          },
        },
      },
      iterateRows: {
        type: z.boolean(),
        title: 'Iterate Rows',
        description: 'Iterate over rows',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
      absRow: {
        type: z.number(),
        title: 'Row',
        description: 'Select the row to extract data from',
        input: Input.Number,
        default: 0,
        conditions: {
          hasValue: {
            reference: 'absolute',
            iterateRows: false,
          },
        },
      },
      relRow: {
        type: z.number(),
        title: 'Row',
        description: 'Shift rows from the reference cell',
        input: Input.Number,
        default: 0,
        conditions: {
          hasValue: {
            reference: 'relative',
            iterateRows: false,
          },
        },
      },
      whitespace: {
        type: z.boolean(),
        title: 'Whitespace',
        description: 'Strip trailing whitespace in the output.',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },

    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  iterateRowByColumn: {
    title: 'Column',
    description: 'Iterate over a column within a table.',
    category: 'table',
    type: 'iterateRowByColumn',
    node: GraphNode.Generic,
    icon: TbColumns3,
    mutation: ({ options }) => {
      const { column } = options;

      return `#>${column}$get`;
    },
    options: {
      column: {
        type: z.number(),
        title: 'Column',
        description: 'Select target column to iterate over',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
});

export const LOGIC_BLOCKS = {
  ...logic,
  ...overrides,
};
