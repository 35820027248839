import _ from 'lodash';
import { Box, Menu, Portal } from '@mantine/core';
import { GraphOperation } from '@morph-mapper/types';
import { Options } from '@morph-mapper/ui';
import { FiTrash2 } from 'react-icons/fi';
import { TbReplace } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useGraphContext } from '../../context';
import { useReactFlow } from 'reactflow';

interface NodeMenuProps {
  nodeId: string;
}

export const NodeMenu = ({ nodeId }: NodeMenuProps) => {
  const [edges, deleteNodes] = useGraphContext((s) => [
    s.getEdges(),
    s.deleteNodes,
  ]);
  const navigate = useNavigate();

  const recursiveFindNodeIds = (nodeId: string): string[] => {
    const nodeIds = [nodeId];

    const nodesToDelete = _.filter(edges, (edge) => edge.target === nodeId).map(
      (edge) => edge.source
    );

    nodeIds.push(...nodesToDelete);

    nodesToDelete.forEach((nodeId) => {
      nodeIds.push(...recursiveFindNodeIds(nodeId));
    });

    return nodeIds;
  };

  const handleChangeType = () => {
    navigate('nodes/new', {
      state: {
        operation: GraphOperation.Replace,
        nodeId,
      },
    });
  };

  const handleDelete = () => {
    const nodeIds = recursiveFindNodeIds(nodeId);
    deleteNodes(nodeIds);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Menu shadow="md" width={200} position="bottom-end">
        <Menu.Target>
          <Options orientation="vertical" />
        </Menu.Target>
        <Portal>
          <Menu.Dropdown sx={{ zIndex: 1000 }}>
            <Menu.Item icon={<TbReplace />} onClick={handleChangeType}>
              Change Type
            </Menu.Item>
            <Menu.Item icon={<FiTrash2 />} onClick={handleDelete}>
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Box>
  );
};
