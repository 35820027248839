import { Getter, Setter, createLens } from '@dhmk/zustand-lens';
import { Node, isBoolean } from '@/types';
import { Draft } from 'immer';
import { GraphsSlice } from '.';
import { LogicBlockIndex } from '@morph-mapper/node-logic';
import {
  OptionType,
  OptionVisibility,
  isOptionEntry,
} from '@morph-mapper/types';

export interface INode {
  get: () => Node;
  getId: () => string;
  getType: () => LogicBlockIndex;

  getQuickView: () => boolean;
  setQuickView: (isOpen: boolean) => void;

  getOptions: () => Record<string, any>;
  setOptionType: (id: string, type: OptionType) => void;
  getOptionType: (id: string) => OptionType;
  getOptionValue: (id: string) => any;
  setOptionValue: (id: string, value: any) => void;
  getOptionVisibility: (id: string) => OptionVisibility;
  setOptionVisibility: (id: string, visibility: OptionVisibility) => void;

  getDependencies: () => Record<string, boolean | Record<string, boolean>>;
  setDependency: (dependency: string, topic?: string) => void;
  clearDependency: (dependency: string) => void;
}

export const createNodeAPI = (
  _set: Setter<Draft<GraphsSlice>>,
  _get: Getter<Draft<GraphsSlice>>,
  graphId: string,
  nodeId: string
): INode => {
  const [set, get] = createLens(_set, _get, [
    'graphs',
    graphId,
    'nodes',
    nodeId,
  ]) as [Setter<Draft<Node>>, Getter<Draft<Node>>];

  return {
    get: () => {
      return get();
    },
    getId: () => {
      return nodeId;
    },
    getType: () => {
      return get().type;
    },

    getQuickView: () => {
      return get().data.ui.quickView.isOpen;
    },
    setQuickView: (isOpen) => {
      set((s) => {
        s.data.ui.quickView.isOpen = isOpen;
      });
    },

    getOptions: () => {
      // FIXME: Fix to prevent graph view crash when a node is deleted.
      if (get() === undefined) {
        return {};
      }

      return get().data.logic.options;
    },

    setOptionType: (id, type) => {
      set((s) => {
        const option = s.data.logic.options[id];

        if (option === undefined) throw 'Invalid option id';

        option.type = type;
      });
    },
    getOptionType: (id) => {
      const option = get().data.logic.options[id];
      const isDependency = get().data.graph.dependencies[id];

      if (!isOptionEntry(option, isDependency)) {
        throw new Error('Invalid option type');
      }

      return option.type;
    },
    getOptionValue: (id) => {
      const option = get().data.logic.options[id];
      const isDependency = get().data.graph.dependencies[id];

      if (!isOptionEntry(option, isDependency)) {
        throw new Error('Invalid option type');
      }

      return option.value;
    },
    setOptionValue: (id, value) => {
      set((s) => {
        const option = s.data.logic.options[id];
        if (!isOptionEntry(option)) {
          throw new Error('Invalid option type');
        }

        option.value = value;
      });
    },
    getOptionVisibility: (id) => {
      const option = get().data.logic.options[id];
      const isDependency = get().data.graph.dependencies[id];

      if (!isOptionEntry(option, isDependency)) {
        throw new Error('Invalid option type');
      }

      return option.visibility;
    },
    setOptionVisibility: (id, visibility) => {
      set((s) => {
        const option = s.data.logic.options[id];
        if (!isOptionEntry(option)) {
          throw new Error('Invalid option type');
        }

        option.visibility = visibility;
      });
    },

    getDependencies: () => {
      return get().data.graph.dependencies;
    },
    setDependency: (dependency, topic) => {
      set((s) => {
        const dependencies = s.data.graph.dependencies;

        if (topic) {
          if (!dependencies[topic]) dependencies[topic] = {};

          const topicDependencies = dependencies[topic];
          if (topicDependencies === undefined)
            throw 'Dependency topic undefined';
          if (isBoolean(topicDependencies)) throw 'Invalid dependency topic';

          topicDependencies[dependency] = true;
        } else {
          dependencies[dependency] = true;
        }
      });
    },
    clearDependency: (dependency) => {
      set((s) => {
        delete s.data.graph.dependencies[dependency];
      });
    },
  };
};
