import { Textarea } from '@mantine/core';
import { BaseNode } from '../node-base';
import { NodeProps } from 'reactflow';
import { LogicBlockIndex } from '@morph-mapper/node-logic';
import { useOutputNode } from './use-output-node';
import { useStore } from '@/store';

export const NodeOutput = (node: NodeProps) => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const { output } = useOutputNode();

  return (
    <BaseNode
      id={node.id}
      type={node.type as LogicBlockIndex}
      postAction={
        <Textarea value={output ?? 'undefined'} minRows={2} disabled autosize />
      }
      variant={variant}
    />
  );
};
