import { useRef } from 'react';
import { GraphContext } from './context';
import { GraphStore, createGraphStore } from '../../store';
import { useGraphLocation } from '@/hooks';
import { useStore } from '@/store';
import { useLogicBlocks } from '@morph-mapper/node-logic';

interface GraphProviderProps extends React.PropsWithChildren {
  id: string;
}

export const GraphProvider = ({
  children,
  ...props
}: Omit<GraphProviderProps, 'location'>) => {
  const { state } = useGraphLocation();
  const variant = useStore(({ config: c }) => c.getVariant());
  const { logicBlocks } = useLogicBlocks(variant);

  const globalStore = useStore();
  const storeRef = useRef<GraphStore>();
  if (!storeRef.current) {
    storeRef.current = createGraphStore(globalStore, {
      ...props,
      location: state,
      logicBlocks,
    });
  }

  return (
    <GraphContext.Provider value={storeRef.current}>
      {children}
    </GraphContext.Provider>
  );
};
