import { useEffect, useMemo } from 'react';
import { useReactFlow } from 'reactflow';
import { graphlib, layout } from '@dagrejs/dagre';
import { Node, Edge } from '@/types';
import { useGraphContext } from '../../context';
import {
  GRAPH_LAYOUT_DIRECTION,
  LAYOUT_NODE_HEIGHT,
  LAYOUT_NODE_WIDTH,
} from '@/config';
import { getValues } from '@morph-mapper/utils';

/**
 * Applies a graph layout to the visualized graph
 * by fetching the nodes and updating the coordinates.
 */
export const useGraphLayout = () => {
  /* Context State */
  const [{ nodes, edges }, setNodes, setEdges] = useGraphContext((s) => [
    s.get(),
    s.setNodes,
    s.setEdges,
    s.node,
  ]);
  /* Local State */
  const g = useMemo(
    () => new graphlib.Graph().setDefaultEdgeLabel(() => ({})),
    []
  );
  /* Hooks */
  const { fitView } = useReactFlow();

  /**
   * Effects
   **/
  useEffect(() => {
    const layouted = getLayoutedElements(getValues(nodes), getValues(edges));

    setNodes(layouted.nodes);
    setEdges(layouted.edges);

    window.requestAnimationFrame(() => {
      fitView();
    });
  }, [nodes, edges]);

  const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
    g.setGraph({ rankdir: GRAPH_LAYOUT_DIRECTION });

    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) => {
      g.setNode(node.id, {
        width: LAYOUT_NODE_WIDTH,
        // FIXME: height should be dynamically adjusted based on the number of items in the quick view.
        height: LAYOUT_NODE_HEIGHT * 2.5,
      });
    });

    layout(g);

    return {
      nodes: nodes.reduce((acc, node) => {
        const { x, y } = g.node(node.id);

        return { ...acc, [node.id]: { ...node, position: { x, y } } };
      }, {}),
      edges: edges.reduce((acc, edge) => {
        return { ...acc, [edge.id]: edge };
      }, {}),
    };
  };
};
