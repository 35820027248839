import { Action, ActionProps } from '../action';
import { forwardRef } from 'react';
import { PiDotsThreeOutline, PiDotsThreeOutlineVertical } from 'react-icons/pi';

interface OptionsProps extends ActionProps {
  orientation?: 'horizontal' | 'vertical';
}

export const Options = forwardRef<HTMLButtonElement, OptionsProps>(
  ({ orientation = 'horizontal', variant, ...props }: OptionsProps, ref) => {
    return (
      <Action
        {...props}
        ref={ref}
        Icon={
          orientation === 'horizontal'
            ? PiDotsThreeOutline
            : PiDotsThreeOutlineVertical
        }
        variant={variant ?? 'light'}
      />
    );
  }
);
