import {
  ActionIcon,
  Box,
  Card,
  Group,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useBaseNode } from './useBaseNode';
import { OptionVisibility, type SchemaVariant } from '@morph-mapper/types';
import { LogicBlockIndex, useLogicBlocks } from '@morph-mapper/node-logic';
import { t } from 'i18next';
import {
  HiMiniChevronDoubleDown,
  HiMiniChevronDoubleUp,
} from 'react-icons/hi2';
import { useEffect, useState } from 'react';
import { useGraphContext } from '../../context';
import { getEntries } from '@morph-mapper/utils';
import { useReactFlow } from 'reactflow';

interface BaseNodeProps {
  id: string;
  type: LogicBlockIndex;
  preAction?: React.ReactNode;
  postAction?: React.ReactNode;
  variant: SchemaVariant;
}

export const BaseNode = ({
  id,
  type,
  preAction,
  postAction,
  variant,
}: BaseNodeProps) => {
  const INode = useGraphContext((s) => s.node(id));
  const { logicBlocks } = useLogicBlocks(variant);
  const { title, category, icon: Icon } = logicBlocks[type];
  const { getTemplateHandles } = useBaseNode(type, variant);

  const exposedOptions = getEntries(INode.getOptions()).filter(
    ([, value]) => value.visibility === OptionVisibility.Exposed
  );
  const hasExposedOptions = exposedOptions.length > 0;

  return (
    <Box sx={{ position: 'relative' }}>
      <Card
        withBorder
        radius="md"
        pb={hasExposedOptions ? 0 : undefined}
        sx={{ overflow: 'visible' }}
      >
        <Card.Section inheritPadding py="xs">
          <Group>
            {preAction}
            <ThemeIcon variant="default" size="xl">
              <Icon />
            </ThemeIcon>
            <Stack spacing={2} mr="xl">
              <Title order={6}>{t(title)}</Title>
              <Text size="xs" c="dimmed" tt="capitalize" sx={{ maxWidth: 200 }}>
                {category}
              </Text>
            </Stack>
            {postAction}
          </Group>
        </Card.Section>

        {hasExposedOptions && (
          <QuickView id={id} exposedOptions={exposedOptions} />
        )}
      </Card>

      {...getTemplateHandles()}
    </Box>
  );
};

interface QuickViewProps {
  id: string;
  exposedOptions: [string, any][];
}

export const QuickView = ({ id, exposedOptions }: QuickViewProps) => {
  const INode = useGraphContext((s) => s.node(id));
  const [isOpen, setIsOpen] = useState(false);

  const theme = useMantineTheme();

  useEffect(() => {
    INode.setQuickView(isOpen);
  }, [isOpen]);

  return (
    <Box>
      <Card.Section
        inheritPadding
        withBorder
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[0],
          opacity: isOpen ? 1 : 0,
          visibility: isOpen ? 'visible' : 'hidden',
          height: isOpen ? 'auto' : 0,
        })}
        py={isOpen ? 'xs' : 0}
      >
        <Stack spacing="xs">
          {exposedOptions.map(([key, { value }]) => (
            <TextInput
              key={key}
              label={t(key)}
              value={value}
              size="xs"
              disabled
            />
          ))}
        </Stack>
      </Card.Section>

      <Card.Section inheritPadding py={isOpen ? '0.5em' : 0}>
        <ActionIcon
          variant="default"
          size="xs"
          sx={{ position: 'absolute', left: 15, bottom: -10 }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <HiMiniChevronDoubleUp color={theme.colors.gray[6]} size={15} />
          ) : (
            <HiMiniChevronDoubleDown color={theme.colors.gray[6]} size={15} />
          )}
        </ActionIcon>
      </Card.Section>
    </Box>
  );
};
