import { OptionsRecord } from '@morph-mapper/node-logic';
import {
  NodeOptionsWrapper,
  OptionType,
  OptionVisibility,
} from '@morph-mapper/types';
import { getEntries } from '@morph-mapper/utils';

/**
 * This function parses the options field of a LOGIC_BLOCKS object and returns
 * a record of default values for each option. This is used to initialize the
 * option fields for a new node.
 */
export const parseDefaultOptions = (options: OptionsRecord) => {
  return getEntries(options).reduce((acc, [name, optionConfig]) => {
    const isConnector = optionConfig.conditions.isConnector;

    acc[name] = {
      type: isConnector ? OptionType.Dependency : OptionType.UserDefined,
      value: optionConfig.default,
      visibility: OptionVisibility.Internal,
    };
    return acc;
  }, {} as NodeOptionsWrapper<OptionsRecord>);
};
