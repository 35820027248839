import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { Node, Edge } from '@/types';
import {
  GraphState as GraphLocationState,
  isGraphAddLocation,
  isGraphInsertLocation,
  isGraphReplaceLocation,
  isGraphAddDepLocation,
} from '@/hooks';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { parseDefaultOptions } from '@/utils';
import { IGraph, StoreSlice } from '@/store';
import { GraphOperation, Handle } from '@morph-mapper/types';
import { LogicBlockIndex } from '@morph-mapper/node-logic';

export interface GraphProps {
  logicBlocks: any;
  // Required context for view
  location: GraphLocationState;

  id: string;
}

export type InitGraphProps = GraphProps;

export interface GraphState extends GraphProps {
  // Local state operations
  createSubGraph: (type: LogicBlockIndex) => void;
  replaceNode: (node: Node) => void;
  insertNode: (node: Node) => void;
  addNode: (node: Node) => void;
}

type InitGraphState = Pick<GraphState, 'logicBlocks'>;

export type GraphStore = ReturnType<typeof createGraphStore>;

export const createGraphStore = (
  global: StoreSlice,
  { ...initProps }: InitGraphProps & InitGraphState
) => {
  return createStore<GraphState & StoreSlice & IGraph>()(
    immer((set, get) => {
      return {
        ...global,
        ...global.graphs.graph(initProps.id),
        ...initProps,

        createSubGraph: (type) => {
          // Construct default structure and values for node type
          const { handles, options } = get().logicBlocks[type];
          const newNodeId = nanoid(10);

          const ui = {
            quickView: {
              isOpen: false,
            },
          };
          const graph = {
            handles: structuredClone(handles) as {
              source: Handle[];
              target: Handle[];
            },
            dependencies: {},
          };

          const node: Node = {
            id: newNodeId,
            type,
            data: {
              ui,
              graph,
              logic: {
                options: parseDefaultOptions(options),
              },
            },
            position: { x: 0, y: 0 },
          };

          // Based on the operation provided by the context,
          // perform the appropriate graph operation
          const { location, replaceNode, insertNode, addNode } = get();

          switch (location.operation) {
            case GraphOperation.Replace:
              replaceNode(node);
              break;
            case GraphOperation.Insert:
              insertNode(node);
              break;
            case GraphOperation.Add:
              addNode(node);
              break;
            case GraphOperation.Dependency:
              addNode(node);
              break;
          }
        },
        replaceNode: (node) => {
          const { location } = get();
          if (!isGraphReplaceLocation(location))
            throw new Error('Invalid location, not of type replace');

          const { nodeId } = location;

          set((s) => {
            const nodes = s.getNodes();
            s.setNodes({
              ...nodes,
              [nodeId]: { ...node, id: nodeId },
            });
          });
        },
        insertNode: (node) => {
          const { location } = get();
          if (!isGraphInsertLocation(location))
            throw new Error('Invalid location, not of type insert');

          const { sourceEdge, targetEdge } = location;

          const sourceEdgeId = nanoid(10);
          const targetEdgeId = nanoid(10);

          set((s) => {
            const edges = _.filter(
              s.getEdges(),
              (edge) => edge.id !== targetEdge!.id
            );

            s.setNodes({ ...s.getNodes(), [node.id]: node });
            s.setEdges({
              ...edges,
              //@ts-expect-error Need to implement proper typing for data: { type: string }
              sourceEdgeId: {
                ...sourceEdge,
                data: { type: 'template' },
                target: node.id,
                targetHandle: '1',
                id: sourceEdgeId,
              },
              targetEdgeId: {
                ...targetEdge,
                source: node.id,
                sourceHandle: '1',
                id: targetEdgeId,
              },
            });
          });
        },
        addNode: (node) => {
          const { location } = get();
          if (!isGraphAddLocation(location) && !isGraphAddDepLocation(location))
            throw new Error('Invalid location, not of type add');

          const { targetEdge } = location;

          const edge: Edge = {
            id: nanoid(10),
            type: 'default',
            data: { type: 'template' },
            source: node.id,
            sourceHandle: '1',
            ...targetEdge,
          };

          set((s) => {
            s.setNodes({ ...s.getNodes(), [node.id]: node });
            s.setEdges({ ...s.getEdges(), [edge.id]: edge });
          });
        },
      };
    })
  );
};
