import { ActionIcon, Box } from '@mantine/core';
import { FiSettings } from 'react-icons/fi';
import { BaseNode } from '../node-base';
import { useNodeGeneric } from './use-node-generic';
import { NodeProps } from 'reactflow';
import { Add } from '@morph-mapper/ui';
import { DependencyList } from '../list-dependency';
import { LogicBlockIndex, useRules } from '@morph-mapper/node-logic';
import { useStore } from '@/store';
import { useGraphContext } from '../../context';
import { NodeMenu } from '../node-menu';

export const GenericNode = (node: NodeProps) => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const { isConfigurable, hasMultipleChildren } = useRules(variant);
  const [isHandleConnected] = useGraphContext((s) => [s.isHandleConnected]);
  const { handleBlockConfig, handleCreateNode, dependencies } =
    useNodeGeneric(node);

  return (
    <Box sx={{ position: 'relative' }}>
      {dependencies.length > 0 && (
        <DependencyList
          nodeId={node.id}
          dependencyArray={dependencies}
          isHandleConnected={isHandleConnected}
        />
      )}

      <BaseNode
        id={node.id}
        type={node.type as LogicBlockIndex}
        preAction={
          hasMultipleChildren(node.type as LogicBlockIndex) && (
            <Add onClick={handleCreateNode} />
          )
        }
        postAction={
          <>
            {isConfigurable(node.type as LogicBlockIndex) && (
              /* Button which redirects to a configuration panel with template generation options. */
              <ActionIcon onClick={handleBlockConfig}>
                <FiSettings />
              </ActionIcon>
            )}
            {/* Menus that offers quick actions for the current node. */}
            <NodeMenu nodeId={node.id} />
          </>
        }
        variant={variant}
      />
    </Box>
  );
};
