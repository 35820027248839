import { HandleType } from '@morph-mapper/types';
import { Node, Edge } from '@/types';

export const initialNodes: Record<string, Node> = {
  seed: {
    id: 'seed',
    position: { x: 0, y: 0 },
    data: {
      ui: {
        quickView: {
          isOpen: false,
        },
      },
      graph: {
        dependencies: {},
        handles: {
          source: [
            {
              id: '1',
              type: HandleType.Template,
            },
          ],
          target: [],
        },
      },
      logic: {
        options: {},
      },
    },
    type: 'seed',
  },
  root: {
    id: 'root',
    position: { x: 0, y: 400 },
    data: {
      ui: {
        quickView: {
          isOpen: false,
        },
      },
      graph: {
        dependencies: {},
        handles: {
          source: [],
          target: [
            {
              id: '1',
              type: HandleType.Template,
            },
          ],
        },
      },
      logic: {
        options: {},
      },
    },
    type: 'result',
  },
};

export const initialEdges: Record<string, Edge> = {
  default: {
    id: 'default',
    source: 'seed',
    target: 'root',
    type: 'default',
    data: { type: 'template' },
    sourceHandle: '1',
    targetHandle: '1',
  },
};
