import { hasProperty } from '@morph-mapper/utils';

export enum HandleType {
  Template = 'template',
  Options = 'options',
}

/**
 * Defines the structure of a handle, which defines the connection point for an edge to a node.
 *
 * @property {string} id - The unique identifier of the handle.
 */
export type Handle = {
  id: string;
  type: HandleType;
};

/**
 * Determines the type of operation that is performed on the graph.
 *
 * @property {string} Replace - The selected node is replaced with a new node.
 * @property {string} Insert - A new node is inserted into the graph, where the action originated from an edge.
 * @property {string} Add - A new node is added to the graph, where the action originated from a node.
 * @property {string} Dependency - A dependency is added to the graph. Dependencies are used to differentiate
 * between multiple nodes that are connected to the same node, but that are consumed by different options,
 */
export enum GraphOperation {
  Replace = 'replace',
  Insert = 'insert',
  Add = 'add',
  Dependency = 'dependency',
}

/**
 * Determines how the value of the option is defined. The value is used to determine which UI elements to show in the configuration panel.
 *
 * @property {string} UserDefined - The value is defined by the user directly in the configuration panel.
 * @property {string} Reference - The value is based on a reference in a sibling/parent entry,
 * most likely exposed via a $declareVariable or $data object.
 * @property {string} Dependency - The value is defined through a dependency of the node.
 */
export enum OptionType {
  UserDefined = 'user-defined',
  Reference = 'reference',
  Dependency = 'dependency',
}

/**
 * Determines if the option is exposed in the Quick View of the node within the Graph View.
 *
 * @property {string} Exposed - The option is exposed in the Quick View.
 * @property {string} Internal - The option is hidden in the Quick View.
 */
export enum OptionVisibility {
  Exposed = 'exposed',
  Internal = 'internal',
}

/**
 * Defines the structure of an option entry. An option entry represents a single
 * configuration option for the respective template code, as defined in the node-logic package.
 * Which options are available is defined by the current node type, which is one of LogicBlockIndex.
 *
 * @property {OptionType} type - Determines how the value of the option is defined.
 * @property {T} value - The value of the option.
 * @property {OptionVisibility} visibility - Determines if the option is exposed in the Quick View of the node within the Graph View.
 */
export type OptionEntry<T> = {
  type: OptionType;
  value: T;
  visibility: OptionVisibility;
};

export type OptionEntryMap = {
  type: OptionType;
  value: Record<string, OptionEntry<any>>;
};

/**
 * Defines the key value mapping for each option entry in the respetive configuration space of the node.
 *
 * Important! The key represents template code, this key thus ends up in the final generated template code.
 */
export type NodeOptionsWrapper<T> = {
  [key in keyof T]: OptionEntry<any> | OptionEntryMap;
};

export type NodeUIData = {
  quickView: {
    isOpen: boolean;
  };
};

/**
 * Defines the structure of the graph data of a node. This data is used for rendering nodes and edges in the graph view.
 * In addition, it is used to determine the relationship between nodes, both child -> parent and dependencies.
 *
 * @property {Record<string, boolean | Record<string, boolean>>} dependencies - A record of dependencies that the current node consumes.
 * @property {Handle[]} handles - A record of handles, used to render the edges of the graph.
 */
export type NodeGraphData = {
  dependencies: Record<string, boolean | Record<string, boolean>>;
  handles: {
    source: Handle[];
    target: Handle[];
  };
};

/**
 * Wraps the graph data and the logic data of a node into a single object,
 * this object is stored within the node object.
 */
export type NodeData<T> = {
  ui: NodeUIData;
  graph: NodeGraphData;
  logic: {
    options: NodeOptionsWrapper<T>;
  };
};

/**
 * Type guards
 */

export const isOptionEntry = (
  entry: any,
  isDependency?: any
): entry is OptionEntry<any> => {
  if (entry === undefined) return false;

  // If not an object the following checks will fail
  if (typeof entry !== 'object') {
    return false;
  }

  // Dependency entries do not have a value (e.g. skip and columns)
  if (isDependency === true) {
    return hasProperty(entry, 'type');
  }

  return hasProperty(entry, 'type') && hasProperty(entry, 'value');
};

export const isOptionEntryMap = (option: any): option is OptionEntryMap => {
  if (option === undefined) return false;
  // If not an object the following checks will fail
  if (typeof option !== 'object') {
    return false;
  }
  // Arrays are also objects, so we need to exclude them when the value is an object
  if (typeof option.value !== 'object' || Array.isArray(option.value)) {
    return false;
  }

  return hasProperty(option, 'type') && hasProperty(option, 'value');
};
